/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

export const GET_MENUS = gql`
  {
    appMenu {
      code
      name
      displayDetails {
        id
        title
        shortDescription
        image {
          cdnUrl
        }
        contentType
        slug
      }
    }
  }
`;

export const GET_MENU_DETAIL = gql`
  query displayDetails($id: ID!) {
    displayDetail(id: $id) {
      title
      image {
        cdnUrl
      }
      duration
      contentUrl
      contentType
      slug
      description
      shortDescription
    }
  }
`;

/** Tags con Paginación */
export const GET_TAGS = gql`
  query tags($first: Int, $after: String) {
    tags(first: $first, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          label
          description
        }
      }
    }
  }
`;

/** Guardar los tags seleccionados */
export const STORAGE_TAGS = gql`
  mutation updateProfileTags($input: ProfileTagsMutationInput!) {
    updateProfileTags(input: $input) {
      profile {
        tags {
          label
        }
      }

      errors {
        field
        messages
      }
    }
  }
`;

/** Obtener evaluación por slug / contentUrl */
export const GET_EVALUATION_BY_CODE = gql`
  query evaluation($code: String!) {
    evaluation(code: $code) {
      id
      code
      title
      sections {
        id
        codigo
        code
        title
        questions {
          id
          code
          text
          position
          answers {
            id
            text
            action
          }
        }
      }
    }

    draftEvaluationAnswer(code: $code) {
      code
      lastSectionId
      lastQuestionId
      draft
      storedOn
    }
  }
`;
/** Obtener evaluación por slug / contentUrl */
export const GET_SECTION_BY_CODE = gql`
  query evaluationSection($code: String!) {
    evaluationSection(code: $code) {
      id
      code
      title
      questions {
        id
        code
        text
        position
        answers {
          id
          text
          action
        }
      }
    }
  }
`;

/** Guardar los las preguntas */
export const STORAGE_EVALUATION = gql`
  mutation createEvaluationAnswer(
    $answers: [EvaluationAnswerInput]
    $code: ID
  ) {
    createEvaluationAnswer(answers: $answers, code: $code) {
      testResult {
        id
        points
        diagnoseLevel
        riskLevel
        riskLevelText
        createdOn
        variable {
          resultImage {
            cdnUrl
          }
        }

        explanations {
          title
          text
          order
        }

        recommendations {
          id
          title
          shortDescription
          contentType
          contentUrl
          image {
            cdnUrl
          }
          program {
            id
            riskLevel
            variable {
              id
              title
            }
          }
          currentProgram {
            id
            riskLevel
          }
        }
      }
    }
  }
`;

/** Guardar evaluación como ausencia */
export const CREATE_EVALUATION_ABSENCE = gql`
  mutation CreateEvaluationAbsence($code: ID) {
    createEvaluationAbsence(code: $code) {
      testResult {
        id
        points
        diagnoseLevel
        riskLevel
        riskLevelText
        explanations {
          order
        }
        variable {
          resultImage {
            cdnUrl
          }
        }
      }
    }
  }
`;

/** Editar diario con la conversación */
export const DRAFT_EVALUATION = gql`
  mutation createUpdateDraftEvaluationAnswer(
    $input: DraftTestAnswerMutationInput!
  ) {
    createUpdateDraftEvaluationAnswer(input: $input) {
      evaluationAnswer {
        id
        code
      }
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;

export const GET_EVALUATION_RESULT = gql`
  query testResult($code: String!, $riskLevel: Int) {
    testResult(code: $code, riskLevel: $riskLevel) {
      diagnoseLevel
      explanations {
        order
        title
        elementType
        text
      }
      recommendations {
        internalName
        title
        contentUrl
        contentType
        program {
          id
        }
      }
    }
  }
`;
