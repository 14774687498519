//Funciones que retornar booleano dependiendo del OS en el que estemos
import AsyncStorage from '@react-native-async-storage/async-storage';
import {PhoneNumberUtil} from 'google-libphonenumber';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/es';
import {Dimensions, Linking, Platform} from 'react-native';
import {now} from 'moment/moment';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {reportError} from '../crashlytics/fbCrashlytics';
import {navigateTo, ROUTE_NAMES} from '../../navigation/MainNavigator';

function isAndroid() {
  return Platform.OS === 'android';
}

function isIOS() {
  return Platform.OS === 'ios';
}

function isWeb() {
  return Platform.OS === 'web';
}

// Funcion que valida si el numero de telefono es valido
function isPhoneNumberValid(number, iso) {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (number.length <= 8 || number.length > (iso ? 12 : 16)) {
    return false;
  }
  try {
    const phone = iso
      ? phoneUtil.parseAndKeepRawInput(number, iso)
      : phoneUtil.parseAndKeepRawInput(number);
    return phoneUtil.isValidNumber(phone);
  } catch (e) {
    return false;
  }
}

// Obtener el numero de telefono sin el codigo de pais
function getNumber(number) {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phone = phoneUtil.parseAndKeepRawInput(number);
    return phone.getNationalNumber();
  } catch (e) {
    console.log('??? ', e);
    return number;
  }
}

// Convertir bytes a GB
function bytesToGB(bytes) {
  try {
    return Math.round(((bytes || 0) / 1024 / 1024 / 1024) * 100) / 100;
  } catch (e) {
    console.log(e);
    return -0.1;
  }
}

// Remove Spaces in Bot Messages
function removeSpace(stringtxt) {
  let result = '';
  let state = '';
  for (let i = 0; i < stringtxt?.length; i++) {
    let c = stringtxt?.[i];
    if (c === ' ' && state === 'inorderednumber') {
    } else {
      result += c;
    }
    if ('0123456789'?.indexOf(c) >= 0) {
      state = 'innumber';
    } else if (
      c === '.' &&
      (state === 'innumber' || state === 'inorderednumber')
    ) {
      state = 'inorderednumber';
    } else if (c === ' ') {
      // same state
    } else {
      state = '';
    }
  }
  return result;
}

/**
 * Order Messages by Timestamp
 *
 */
function orderMessagesByTimestamp(messages, id = 'id') {
  return messages.sort(function (x, y) {
    return x[id] - y[id];
  });
}

// Order and Format Messages
function orderAndFormatMessages(messages) {
  const transformMessage = message => ({
    content: message.content,
    dateTime: moment(new Date(message.server_timestamp)).format(
      'MM/DD/YYYY,hh:mm a',
    ),
    date: new Date(message.server_timestamp),
    bot: message.bot || false,
    flowName: message.flow_name || '',
    responses: message.response_scale || '',
    expectingResponse: message.expecting_response,
    id: message.server_timestamp,
    seen: true,
    seenFirst: false,
    finishingMessage: message.finishing_message,
    extraData: message.extra_data,
    extraParams: message.extra_params,
    feedback: {
      //feedback de mensajes por default
      like: false,
      dislike: false,
      comment: null,
    },
    isLastMessage: false,
  });
  // Si es un solo mensaje
  if (!messages) {
    return null;
  }
  if (messages.server_timestamp) {
    return [transformMessage(messages)];
  }
  // Son varios mensajes

  let messagesArray = Object.values(messages);

  messagesArray = orderMessagesByTimestamp(messagesArray, 'server_timestamp');
  messagesArray = messagesArray.map(transformMessage);
  return messagesArray.reverse();
}

/** Get height and wight */
function getWidthAndHeight() {
  return {
    width: Dimensions?.get('window')?.width,
    height: Dimensions?.get('window')?.height,
  };
}

/** Capitalize a word */
function capitalize(word) {
  if (!word) return word;
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

// function to delay a function for n seconds
function delay(n) {
  return new Promise(function (resolve) {
    setTimeout(resolve, n * 1000);
  });
}

// function to get JSON from local storage
async function getJSONLocalStorage(key) {
  try {
    const item = await AsyncStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  } catch (e) {
    console.log('error al parsear el dato', e);
    return undefined;
  }
}

// function to set JSON to local storage
async function setJSONLocalStorage(key, value) {
  return AsyncStorage.setItem(key, JSON.stringify(value));
}

/** divide los inputs en rows */
function setRowsAndColumns(numOfCol, unidimensionalArray) {
  const noOfRows = Math.ceil((unidimensionalArray?.length || 0) / numOfCol);

  const arrayOfArrays = [...Array(noOfRows).keys()].map(nc => {
    const column = unidimensionalArray.slice(0, numOfCol);
    unidimensionalArray.splice(0, numOfCol);
    return column;
  });
  return arrayOfArrays;
}

/** Dado un string, regresa una lista de categorias con sus elementos */
function getCategoriesList(data) {
  if (!data) {
    return null;
  }
  const _data = JSON.parse(data);
  const categories = _.groupBy(_data, 'category');
  return Object.keys(categories).map(key => ({
    category: key,
    values: categories[key],
  }));
}

/* Regresa un arreglo de numeros dependiendo de dos numero. Range */
function jsRange(max, min = 0) {
  return Array.from({length: max - min + 1}, (_, i) => min + i);
}

// Regresa un diccionario de instrucciones
function getInstructionsAsDict(instructions) {
  return instructions.reduce(
    (res, instruction) => ({
      ...res,
      [instruction.step]: instruction,
    }),
    {},
  );
}

// funcion que divide un arreglo en chunks
function spliceIntoChunks(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

// funcion que regresa el titulo correcto del programa
function getCorrectProgramTitle(translate, programLabel) {
  // if programLabel contains the word "terapia" or "therapy" then return the program label, otherwise return the translate
  if (
    programLabel.toLowerCase().includes('terapia') ||
    programLabel.toLowerCase().includes('therapy')
  ) {
    return programLabel;
  }
  return translate;
}

/* functiones de fechas */

/*
 * Funcion para obtener fecha dependiendo del idioma y del formato
 * 1. Jueves 31 de agosto de 2023 format: dddd LL
 * */
function getFormatDate(date, lang, format) {
  moment.locale(lang);
  const m = moment(date || new Date());
  return m.format(format);
}

/*
 * Funcion para obtener fecha dependiendo del idioma y del formato con horas
 * 1. Jueves 31 de agosto de 2023 a las 12:00 hrs format: dddd LL [a las] HH:mm [hrs]
 * */
function getFormatDateHour(date, lang) {
  moment.locale(lang);
  const m = moment(date || new Date());
  return `${m.format('dddd LL')} a las ${m.format('HH:mm')} hrs`;
}

/*
 * Funcion para obtener fecha dependiendo del idioma y del formato con horas
 * 1. 31 de agosto de 2023 a las 12:00 hrs format: dddd LL [a las] HH:mm [hrs]
 * */
function getFormatDateHour2(date, lang) {
  moment.locale(lang);
  const m = moment(date || new Date());
  return `${m.format('LL')} a las ${m.format('HH:mm')} hrs`;
}

/*
 * Funcion para obtener fecha dependiendo del idioma y del formato
 * 1. 31 agosto 2023 format: dddd LL [a las] HH:mm [hrs]
 * */
function getFormatShortDate(date, lang) {
  moment.locale(lang);
  const m = moment(date || new Date());
  return `${m.format('LL')}`;
}

// función que regresa fecha en formato '20 Julio, 18:00hrs'
function getFormatDayHour(date, lang) {
  moment.locale(lang);
  const m = moment(date || new Date());
  const day = m.format('DD');
  const month = m.format('MMMM');
  const time = m.format('HH:mm');
  return `${day} ${month}, ${time}`;
}

// función que regresa fecha en formato '20 Julio - 18:00 PM'
function getFormatDateAndHour(date, startTime, lang) {
  moment.locale(lang);
  const m = moment(`${date}T${startTime}`);
  const day = m.format('DD');
  const month = m.format('MMMM');
  const time = m.format('hh:mm A'); // Para formato 12 horas con AM/PM
  return `${day} de ${month}, ${time}`;
}

// funcion que regresa el horario el formato iso para calendario
function convertToISOFormat(startTimeISO, addMinutes = 50) {
  const dateTime = moment(startTimeISO);
  const endTimeISO = dateTime.add(addMinutes, 'minutes').toISOString();

  return {
    startTimeISO,
    endTimeISO,
  };
}

// funcion que regresa si es hoy
function isToday(date, lang) {
  moment.locale(lang);
  const m = moment(date);
  return m.isSame(new Date(), 'day');
}

// funcion que regresa el dia de la semana
function getDayOfTheWeek(date) {
  const m = moment(date || new Date());
  return m.day();
}

// funcion que transforma una fecha en un objeto
function transformDateToObject(date) {
  if (date) {
    const dateArray = date.split('-');
    return {
      DD: dateArray[2],
      MM: dateArray[1],
      YYYY: dateArray[0],
    };
  }
}

// funcion que regresa si la fecha es valida
function dateIsValid(dd, mm, yyyy) {
  // const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  // if (dateStr.match(regex) === null) {
  //   return false;
  // }
  // const [day, month, year] = dateStr.split('/');
  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${yyyy}-${mm}-${dd}`;
  const date = new Date(isoFormattedStr);
  const timestamp = date.getTime();
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }
  return date.toISOString().startsWith(isoFormattedStr);
}

function checkUrlStatus(url) {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error, status code: ${response.status}`);
      }
      return response;
    })
    .then(() => true)
    .catch(error => {
      reportError('utils', {url}, 'checkUrlStatus', error);
      return false;
    });
}

// regresa el dia, mes y año
function getDMY(type) {
  if (type === 'Y') {
    const back = 80;

    const year = new Date().getFullYear() - 10;
    const arrayYears = Array.from(
      {length: back},
      (v, i) => year - back + i + 1,
    );
    return arrayYears.map(a => a.toString()).reverse();
  }

  const length = type === 'D' ? 31 : 12;
  const numberDays = Array.from({length}, (x, i) => i + 1);
  return numberDays.map(days => days.toString().padStart(2, '0'));
}

function handleTime(setTime) {
  const currentDate = new Date();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;
  setTime(currentTime);
}

function handleMessageModal(
  warning,
  showMessage,
  messageText,
  setIsWarning,
  setShowModalMessage,
  setMessage,
) {
  setIsWarning(warning);
  setShowModalMessage(showMessage);
  setMessage(messageText);
}

function handleTimeReturn(time) {
  const currentDate = new Date();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

/* Funciones para las sessiones en vivo*/

function isNow(session) {
  if (!session) return false;
  if (session?.timeToStart) {
    return session?.timeToStart <= 0 && session?.timeToEnd > 0;
  }
  return (
    session &&
    session.presentationDate <= now() &&
    session.endPresentationDate >= now()
  );
}

export function getDateAndTime(session) {
  const {t, i18n} = useTranslation();

  if (!session || !session.presentationDate) {
    return '';
  }
  /**
   * 1. Si es ahora mostrar solo la hora
   * 2. Si es hoy mostrar Hoy y la hora
   * 3. Otherwise, mostrar todo
   */
  const fromHours = `${getFormatDate(
    session.presentationDate,
    i18n?.language,
    'H:mm',
  )}`;
  const endHours = `${getFormatDate(
    session.endPresentationDate,
    i18n?.language,
    'H:mm',
  )}`;

  const time = t('lives:timeFromTo', {fromHours, endHours});

  if (isNow(session)) {
    return time;
  }
  if (isTodaysLive(session)) {
    return t('lives:today', {time: time});
  }

  let dateAndTime = t('lives:dateTime', {
    date: getFormatDate(session.presentationDate, i18n?.language, 'dddd LL'),
    time: getFormatDate(session.presentationDate, i18n?.language, 'H:mm'),
  });

  return dateAndTime;
}

function isTodaysLive(session) {
  return session && isToday(session.presentationDate, i18n?.language);
}

// function para transformar URL (manejar interna o externa)
function handleUrlNavigation(url, useWebView = false, extraParams = {}) {
  if (url.startsWith('tel:')) {
    Linking.openURL(url);
    return;
  }

  const params = {};
  const queryString = url.split('?')[1];
  if (!queryString) {
    const parts = url?.split('/'); // Divide la URL en partes usando "/"
    const lastPart = parts?.[parts?.length - 1]; // Obtiene el último elemento del arreglo
    // hotfix para nom: los dynamic links no funcionan si estas dentro de la app
    switch (lastPart) {
      case 'home':
        navigateTo(ROUTE_NAMES.home);
        return;
      case 'tools':
        navigateTo(ROUTE_NAMES.home, {screen: 'Herramientas'});
        return;
      case 'therapy':
        navigateTo(ROUTE_NAMES.home, {screen: 'Terapia'});
        return;
      case 'diary':
        navigateTo(ROUTE_NAMES.diary);
        return;
      case 'account':
      case 'cuenta':
        navigateTo('Account');
        return;
      default:
        if (useWebView) {
          navigateTo(ROUTE_NAMES.webView, {url, ...extraParams});
        } else {
          Linking.canOpenURL(url).then(() => {
            Linking.openURL(url);
          });
        }
        return;
    }
  }
  const pairs = queryString.split('&');

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    params[decodeURIComponent(key)] = decodeURIComponent(value || '');
  });

  if (params.link) {
    const nestedUrl = decodeURIComponent(params.link);
    const nestedQueryString = nestedUrl.split('?')[1];
    const nestedPairs = nestedQueryString.split('&');

    nestedPairs.forEach(pair => {
      const [key, value] = pair.split('=');
      params[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });
  }
  const routeName = ROUTE_NAMES[params?.route];
  navigateTo(routeName, {id: params?.id, ...extraParams});
}

// función que regresa fecha en formato '30 septiembre, 2024'
function getFormatDayMonthYear(date, lang) {
  moment.locale(lang);
  const m = moment(date || new Date());
  const day = m.format('DD');
  const month = m.format('MMMM');
  const year = m.format('YYYY');
  return `${day} ${month}, ${year}`;
}

function getFormattedDateTime(
  date,
  timezone = 'America/Mexico_City',
  lang = 'es',
) {
  let m = null;
  try {
    timezone = timezone ?? 'America/Mexico_City';
    lang = lang ?? 'es';
    moment.locale(lang);
    m = moment.utc(date).tz(timezone);
  } catch (e) {
    console.log('error en getFormattedDateTime', e);
    reportError(
      'Error al usar moment',
      {date, timezone, lang},
      'getFormattedDateTime',
      e,
    );
    moment.locale(lang);
    m = moment(date || new Date());
  }

  const day = m?.format('DD');
  const month = m?.format('MMMM');
  const time = m?.format('HH:mm');

  return `${day} ${month}, ${time}`;
}

export {
  isAndroid,
  isIOS,
  isWeb,
  getNumber,
  isPhoneNumberValid,
  bytesToGB,
  removeSpace,
  orderMessagesByTimestamp,
  orderAndFormatMessages,
  getWidthAndHeight,
  capitalize,
  delay,
  getJSONLocalStorage,
  setJSONLocalStorage,
  setRowsAndColumns,
  getCategoriesList,
  jsRange,
  getInstructionsAsDict,
  spliceIntoChunks,
  getCorrectProgramTitle,
  isToday,
  getDayOfTheWeek,
  transformDateToObject,
  getFormatDate,
  getFormatDateHour,
  getFormatDateHour2,
  getFormatDateAndHour,
  convertToISOFormat,
  getFormatShortDate,
  getFormatDayHour,
  dateIsValid,
  getDMY,
  checkUrlStatus,
  handleTime,
  handleMessageModal,
  handleTimeReturn,
  isNow,
  handleUrlNavigation,
  getFormatDayMonthYear,
  getFormattedDateTime,
};
