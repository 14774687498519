export default {
  placeholder: 'Write something...',
  placeholderProgram: 'What could be improved?',
  messageNotRead: 'unread message',
  messagesNotRead: 'unread messages',
  registerMood: 'Write your mood',
  chooseTheme: 'Choose a theme',
  help: 'Help',
  settings: 'Settings',
  logout: 'Logout',
  noThemes: 'No themes available',
  send: 'Send',
  chatIntro1: 'You will enter a chat where you can talk more freely with me.',
  chatIntro2:
    "I'll ask you some questions so that little by little you understand your emotions more, okay?",
  conversationHistoryTitle: 'Conversation of the {{date}}',
  aiAboutSubTitle: 'Mindsurf + AI',
  feedbackButton: 'Send',
  aiAboutButton: 'Got it',
  aboutAssistantTitle: 'About the assistant',
  sessionTherapy: 'Schedule a session with a therapist',
  explore: 'Explore network',
  seeProgram: 'See program',
  startEvaluation: 'See evaluation',
  startExercise: 'Start exercise',
  feedback: 'Did this exercise help increase your emotional well-being?',
  feedbackProgram: 'This session helped you improve your emotional well-being?',
  sessions: 'sessions',
  backHome: 'Log out',
  chatVoice: "Let's talk by voice",
  chatVoiceDesc:
    'Now you can dictate by voice what you want to talk to your emotional assistant about. Would you like to give it a try?',
  useVoice: 'Use voice',
  useText: 'Use chat',
  new: 'NEW',
  authorize: 'You will need to authorize the use of your microphone.',
  emotionalAssistant: 'Emotional assistant',
  modalVoice: 'Hold down to speak.',
  cancelMessage: '← Drag to cancel',
  byeButton: 'Goodbye',
  cancelButton: 'Cancel',
  alertDevice: 'Unsupported Device',
  alertDeviceDesc: 'Your device is not compatible for this functionality',
  permissionMicro: 'Microphone Permission Required',
  permissionMicroDesc: 'You need to grant microphone permission from Settings.',
  answersChat: 'Some responses:',
  modalInfoBoxAssistant:
    "Here you can talk to Mindsurf's emotional assistant about how you feel. It doesn't matter if your day was good or bad; it will always find a way to help you with exercises and reflections.\n\nTalk to it through text messages. Just keep in mind that the more you talk to it, the better recommendations it can provide.",
  voiceStreamingTitle: 'Emotional Assistant Call',
  voiceMessagePermissions:
    "Speak to the assistant in a call. When you start, you will hear its voice and can respond without pressing any buttons. \n\n You'll need to authorize the use of your microphone.",
  conectionTypeMessages:
    '\nTo speak with the assistant via voice, your phone must be connected to 4G, 5G, or Wi-Fi.',
  startButton: 'Start',
  voiceMessageBlockedTitle: 'Microphone Permission Required',
  voiceMessageBlocked:
    "Oops! It seems you've blocked microphone access. To enjoy this feature, please unblock permissions in your phone settings. Click the button below to go directly to the settings.",
  voiceMessageBlockedButton: 'Permission Settings',
  voiceMessageBlockedButtonCancel: 'Cancel',
  titleBanner: 'I am your emotional assistant.',
  messageBanner: 'Do you want to chat or vent?',
  callFinished: 'The call has ended.',
  callError: 'There was an error in the call.',
  notInternetReachable:
    "Oops! It seems you have blocked access to the microphone. To enjoy this feature, please unblock permissions in your phone's settings. Click the button below to go directly to the settings.",
  recording1: 'Start speaking',
  recording2: 'Listening',
  recording3: 'Finish speaking to send message',
  processing1: 'Thinking',
  processing2: "Reflecting, I'm with you in a moment",
  processing3: "Processing, I'm here to help",
  emergencyChat: 'Emergency chat',
  notTranscription: "We can't hear you... Please try again.",
  reconnecting: 'Reconnecting. Please wait.',
  notReconnected: 'We had a problem. Please try again later.',
  finish: 'Finish',
  monthlyTitle: 'This month of {{month}}...',
  weeklyTitle: 'This week...',
  insightLoading:
    'Creating your personalized insight. This will only take a moment. ✨',
  weeklyDescNoData:
    "We don't have enough information yet. Talk to the emotional assistant to start generating your reports! 💬",
  monthlyDescNoData:
    'Interact with the assistant at least twice a month to get this report. Remember that more interactions with the assistant will bring you more insights about your well-being! 💬✨',
  themes: 'Detected themes',
  emotions: 'Main emotions',
  recommendations: 'Recommendations',
  insights: 'Insights',
  insightsDesc:
    'Get to know yourself better through your insights from the emotional assistant.',
  insightsTitleNav: 'Your insights at the moment',
  seeHistoryInsights: 'Take a look at your history',
  historyInsightsDesc:
    'How about a quick check on your progress? Your interaction history is waiting for you!',
  goToAssistant: 'Go to assistant',
  historyInsights: 'Insights history',
  emptyHistory:
    "You don't have any history available yet. Talk to the emotional assistant to start collecting information and begin your journey.",
  cardHistory: '📈 Insights • {{date}}',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  titlePrivacy: 'We care about your privacy',
  descriptionPrivacy:
    'Your privacy is our priority. Everything you share with the emotional assistant is safe and protected, ensuring that no one else can access your personal data or conversations. 🤝🔒',
  titleProgress: 'Your personal progress',
  descriptionProgress:
    'An insight is a deep understanding obtained by analyzing your data. AI-generated insights help you better understand your interactions with the emotional assistant, offering you a clear vision of your well-being and suggestions for your personal growth. 💡',
  themesDetected: {
    theme: {
      icon: '🔍',
      title: 'Detected themes',
      text: 'Here you can see the main themes detected in your conversations.',
    },
    emotions: {
      icon: '⛅️',
      title: 'Main emotions',
      text: 'In this section, you can see your emotions detected in your conversations.',
    },
    recommendations: {
      icon: '🌈',
      title: 'Recommendations',
      text: 'Receive recommendations based on your interactions.',
    },
  },
  slideToCancel: 'Slide to cancel <',
  continueChatting: 'Continue chatting',
  endConversation: 'End conversation',
  endRating: 'Finish',
};
