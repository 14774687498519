/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

//Obtener los programas
export const GET_PROGRAMS = gql`
  {
    programsV1 {
      id
      description
      programType
      image {
        cdnUrl
      }
      homeImage {
        cdnUrl
      }
      variable {
        id
        codigo
        name
        description
        testType
        code
        resultImage {
          cdnUrl
        }
      }
      displayDetail {
        id
        title
        description
        shortDescription
        internalImage {
          cdnUrl
        }
        image {
          cdnUrl
        }
      }
    }
  }
`;

// TODO: AQUI SE ACTUALIZA
export const GET_PROGRAMSV2 = gql`
  query programsV2 {
    programsV1(programVersion: 2) {
      id
      status
      sessionsCount
      programType
      progress {
        started
        finished
        nextSessionOrder
        nextSession {
          id
          order
          title
        }
      }
      sessions {
        id
        title
        status
      }
      image {
        cdnUrl
      }
      displayDetail {
        title
        description
        image {
          cdnUrl
        }
      }
      variable {
        numberSessionsLabel
      }
    }
  }
`;
export const GET_PROGRAMS_V2_HOME = gql`
  query programsV2($programVersion: Int) {
    programsV1(programVersion: $programVersion) {
      id
      programType
      homeImage {
        cdnUrl
      }
      displayDetail {
        id
        title
        internalImage {
          cdnUrl
        }
      }
    }
  }
`;

// Obtener programa por id
export const GET_PROGRAM_BY_ID = gql`
  query programV1($id: ID!) {
    programV1(id: $id) {
      id
      description
      status
      image {
        cdnUrl
      }
      displayDetail {
        id
        title
        description
        shortDescription
        internalImage {
          cdnUrl
        }
        image {
          cdnUrl
        }
      }
      variable {
        code
        numberSessionsLabel
        benefits
      }
      programV1Nodes {
        id
        title
        isEvaluation
        conversation {
          name
        }
        duration
        status
      }
    }
  }
`;

// Obtener programa por id
export const GET_PROGRAM_V2_BY_ID = gql`
  query programV2($id: ID!) {
    programV1(id: $id) {
      id
      status
      description
      sessionsCount
      progress {
        finished
        nextSessionOrder
      }
      displayDetail {
        id
        title
        internalName
        contentUrl
        image {
          cdnUrl
        }
      }
      nextSession {
        id
        order
      }
      sessions {
        id
        order
        status
        title
        internalName
        achievement {
          id
          type
          displayDetails {
            id
            title
            description
            internalName
            shortDescription
            image {
              cdnUrl
            }
          }
        }
      }
      userAchievements {
        id
        isNew
        achievement {
          id
          title
          type
          session {
            id
            order
          }
          displayDetails {
            id
            title
            description
            internalName
            shortDescription
            image {
              cdnUrl
            }
          }
        }
        createdAt
        userRepository {
          id
          exerciseType
        }
      }
    }
  }
`;

export const GET_PROGRAM_SESSION_BY_ID = gql`
  query programSession($id: ID!) {
    programSession(id: $id) {
      id
      order
      duration
      internalName
      achievement {
        id
        type
        displayDetails {
          id
          title
          description
          internalName
          shortDescription
          image {
            cdnUrl
          }
        }
      }
      nextActivity {
        id
        order
        type
        conversationFlow {
          name
        }
        image {
          cdnUrl
        }
        minValue
        maxValue
        templateNameUserRepositoryOutName {
          name
        }
        templateNameUserRepositoryInName {
          id
          name
        }
        userRepositoryInField
        instructions {
          id
          step
          label
          description
        }
        multimedia {
          id
          contentType
          displayDetails {
            id
            title
            duration
            durationInSecs
            contentUrl
            image {
              cdnUrl
            }
          }
        }
        categories {
          id
          options
          minValue
          maxValue
          instructions {
            id
            step
            label
            description
          }
        }
        sliders {
          id
          minValue
          maxValue
          minLabel
          maxLabel
          order
        }
        questions {
          id
          label
          description
          type
          isRequired
          isDisabled
          order
          placeholder
          defaultValue
          numLines
          internalName
          minValue
          maxValue
        }
        multimedia {
          id
          displayDetails {
            id
            title
            duration
            durationInSecs
            contentUrl
          }
          internalName
        }
      }
    }
  }
`;

export const GET_USER_ACHIEVEMENT_BY_ID = gql`
  query userAchievement($id: ID!) {
    userAchievement(id: $id) {
      id
      isNew
      achievement {
        id
        internalName
        title
        description
        points
        activity {
          id
          type
          userRepositoryInField
          instructions {
            step
            label
            description
          }
          multimedia {
            contentType
            displayDetails {
              id
              title
              duration
              durationInSecs
              contentUrl
            }
          }
          image {
            cdnUrl
          }
          maxValue
          categories {
            id
            options
            minValue
            maxValue
            instructions {
              id
              step
              label
              description
            }
          }
        }
      }
      createdAt
      userRepository {
        id
        exerciseType
        data
        userFiles {
          id
          file
          contentType
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_USER_REPOSITORY_BY_TEMPLATE_ID = gql`
  query userRepository($templateUserRepoId: ID!) {
    userRepository(templateNameUserRepositoryId: $templateUserRepoId) {
      id
      exerciseType
      data
      createdAt
      updatedAt
    }
  }
`;

// Mutations
export const STORAGE_ACTIVITY = gql`
  mutation finishSessionActivity($id: ID!, $data: String) {
    finishSessionActivity(activityId: $id, data: $data) {
      nextSessionActivity {
        id
        order
        type
        conversationFlow {
          name
        }
        image {
          cdnUrl
        }
        minValue
        maxValue
        templateNameUserRepositoryOutName {
          name
        }
        templateNameUserRepositoryInName {
          id
          name
        }
        userRepositoryInField
        instructions {
          id
          step
          label
          description
        }
        multimedia {
          id
          contentType
          displayDetails {
            id
            title
            duration
            durationInSecs
            contentUrl
          }
        }
        categories {
          id
          options
          minValue
          maxValue
          instructions {
            id
            step
            label
            description
          }
        }
        sliders {
          id
          minValue
          maxValue
          minLabel
          maxLabel
          order
        }
        questions {
          id
          label
          description
          type
          isRequired
          order
          placeholder
          numLines
          internalName
          minValue
          maxValue
          isDisabled
        }
      }
    }
  }
`;
/*
validationType
                validationValue
                instructions
                categoryList {
                    internalName
                    instructions
                    options
                    minValue
                    maxValue
                }
*
* */

export const UPLOAD_IMAGE_B64 = gql`
  mutation userImageB64($imageB64: String!, $namePrefix: String) {
    userImageB64(imageB64: $imageB64, namePrefix: $namePrefix) {
      userFile {
        id
        name
        file
        contentType
        createdAt
        updatedAt
      }
    }
  }
`;

export const RESET_SESSION_PROGRESS = gql`
  mutation resetSessionProgress($id: ID!) {
    resetSessionProgress(id: $id) {
      sessionProgress {
        id
        order
      }
    }
  }
`;

export const UPDATE_VARIABLE_PROGRESS = gql`
  mutation variableProgressMutation($programId: ID!) {
    variableProgressMutation(programId: $programId) {
      variableProgress {
        id
        currentProgram {
          id
        }
      }
    }
  }
`;

export const CANCEL_PROGRESS_THERAPY = gql`
  mutation cancelProgressMutation($programId: ID!) {
    cancelProgressMutation(programId: $programId) {
      programProgress {
        id
        program {
          status
          id
        }
      }
    }
  }
`;

export const START_PROGRAM = gql`
  mutation startProgram($programId: ID!) {
    startProgram(programId: $programId) {
      programProgress {
        id
      }
    }
  }
`;
