export default {
  diaryOfEmotions: 'Diario emocional',
  chatDiary: 'Diario Emocional Escrito',
  days: {
    monday: 'L',
    tuesday: 'M',
    wednesday: 'X',
    thursday: 'J',
    friday: 'V',
    saturday: 'S',
    sunday: 'D',
  },
  daysHistorial: {
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Mi',
    thursday: 'Ju',
    friday: 'Vi',
    saturday: 'Sa',
    sunday: 'Do',
  },
  months: {
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
  },
  buttonAlwaysShow: 'MOSTRAR SIEMPRE',
  buttonIDK: 'No lo sé',
  close: 'CERRAR',
  continue: 'Continuar',
  continueWithExtendedDiary: 'Continuar escribiendo como te sientes',
  diary: 'Diario',
  emotional: 'Emocional',
  emotionalDiary: 'Diario  emocional',
  emotionalRegister: 'Registro emocional',
  explanation:
    'Este es un espacio para que puedas\nidentificar, explorar y registrar\nlo que sientes cada día.\n¡Todas tus emociones son válidas!',
  extendedConversation: 'Ver entrada',
  history: 'Historial',
  home: 'Terminar charla',
  iThinkIFeel: 'Me siento',
  labelInterested: 'Esto puede interesarte:',
  labelRegisterChat: '¡Hoy expresaste tus\nemociones y sentimientos!',
  labelRegisterToday: '¡Registraste tus emociones!',
  labelSelect: 'Selecciona la que más se parezca.',
  messageAfterRegister: 'Hiciste tu registro emocional diario',
  newEmotionalRegister: 'NUEVO REGISTRO EMOCIONAL',
  noHistory:
    'Todavía no tienes historial. Registra diariamente tus emociones para ver cómo varía tu estado de ánimo.',
  numberSteps: '{{currentStep}} de {{totalStep}}',
  points: ' puntos',
  recommendations: 'Te recomendamos',
  register: 'Esta semana tienes $1 registro.',
  registers: 'Esta semana tienes $1 registros.',
  seeAll: 'VER TODOS',
  seeEmotions: 'Ver emociones',
  seeHistory: 'Ver historial',
  seeResult: 'VER RESULTADO',
  selectPeriodPlaceholder: 'Último mes',
  shortStep1: '¿Qué sientes?',
  shortStep2: '¿Qué emoción\ncrees que sientes?',
  showOthers: 'Mostrar otras',
  step1: '¿Qué emoción sientes hoy?',
  step2: '¿Qué palabra describe mejor cómo te sientes?',
  title: 'Diario emocional',
  todayIFeel: 'Mi emoción',
  understandEmotion: 'Escribe\nen tu diario\nemocional',
  write: 'Escribir más sobre cómo te sientes',
  primaryTitle: '¿Cómo te hace sentir?',
  secondaryTitle: '¿Qué emociones crees que sientes?',
  itMakesMeFeel: 'Me hace sentir:',
  relatedWith: 'Relacionadas con {{emotion}}',
  titleHome: '¿Cómo te sientes hoy?',
  emotions: 'Emociones',
  historyDescription:
    'Los días en el calendario muestran el último registro del día en emoción primaria.',
  writeDiary: 'Escribir en el diario',
  diaryIntro:
    'Este es un espacio para que puedas identificar, explorar y registrar lo que sientes cada día. ¡Todas tus emociones son válidas!',
  noRegister: 'Este día no registraste tus emociones.',
  buttonRegisterEmotions: 'Registrar emociones',
  iDontKnowLabel:
    ' Si no sabes cómo te sientes, puedes recibir ayuda del asistente emocional.',
  openAssistantButton: 'Abrir asistente emocional',
  writingLabel: 'Profundiza en por qué te sientes así',
  optional: 'Opcional',
  writingDescription:
    'Describe la situación que te causó sentirte de esa manera o deja una nota sobre tus emociones.',
  optionNotToday: 'Hoy no, gracias',
  optionYes: 'Sí, platiquemos',
  today: 'Hoy',
  backHome: 'Regresar al inicio',
  page: 'Ver más',
  goAssistant: 'Ir al Asistente Emocional',
};
